:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1400px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 45vh;
    
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #2d6896;
    --effect-color: #4c4d4f;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: var(--text-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-li-a-padding: 10px 15px;
    --link-color: var(--accent-color);
    --link-hover-color: var(--effect-color);
    --map-marker-color: 000;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Arimo;
    --alternative-font-family: Merriweather, Helvetica, Arial, sans-serif;  
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/leaflet2.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fonts/Arimo/all.css";
@import "/fonts/Merriweather/all.css"; /* 300, 300i, 400, 400i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/
.readmore{
    width:100%;
}

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

.container-lrg {
    width: 95%;
    margin: 0 auto;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background: url('/theme/intendit/graphics/mainbg4.png') no-repeat left top; 
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

.maincontent {
    background: #fff;
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.container h1 {
    /*margin: 2rem 0.8rem 0;*/
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.3rem;
}

a {
    color: var(--effect-color);
    transition: 0.5s ease;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {

}

.header-inner {
    max-width: 90%;
    margin: 0 auto;
}

header .container {
    padding :0 0.5em;
}

.topcontact-outer {
    background: none;
}

.topcontact {
    background: var(--accent-color);
    color: #777;
    padding: 0.1rem 1rem;
    text-align: center;
    letter-spacing: .15rem;
}

.topcontact svg {
    margin-left: 1rem;
    margin-right: 0.5rem;
    fill: #777;
}

.topcontact a {
    color: #777;
}

header a {
    text-decoration: none;
}

.logo {
    padding: 0 0 1rem 0;

}

.logo img  {
    max-width: 240px;
    height: auto;
    margin-bottom: -50px;
    position: relative;
    z-index: 2;
}

.menu.menu {

}

.menu li {
    margin:0;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    color: #777; 
    text-transform: uppercase;
    font-family: var(--alternative-font-family);
    letter-spacing: 2px;
}


.menu li a {
    position: relative;
}



.menu li a:hover, .menu .active a {
    color: var(--menu-text-color);
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu .dropdown ul li {
        min-width: 340px;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    background: var(--main-color);
    color: #222;
    font-size: .7rem;
}

.menu.menu .sub-menu-items li a:hover { 
    background: var(--effect-color); 
    color: #fff;
}


/**** Leftmenu ****/

.submenu ul {
    /*margin-top: 2.8rem;*/
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: 0.18rem;
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/
.slick-slider {
    margin-bottom: 0;
}

.topslider .slick-slide {  
    height: var(--slick-slider-height)
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.topslider .slick-title {
    text-align:center;
    color:white;
    /*text-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/
}

.sliderbutton {
    background: var(--accent-color);   
}

.sliderbutton a {
    text-decoration: none;
    padding: 2rem;
}

.intro-content {
    position: absolute;
    top: 0;
    transform: translateY(15vh);
    right: 0;
    left: 0;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 0 #222;
/*    background: rgba(29,105,181,.6); */
    width: 70%;
    margin: 0 auto;
}

.intro-content p {
    font-size: 1.2rem;
}

.intro-content a {
    background: var(--effect-color);
    color: #fff;
    border: none;
    font-size: 1.6rem;
    font-family: var(--alternative-font-family);
}

.intro-content a:hover {
    background: #fff;
}

/** arrows **/
.topslider .slick-prev, .topslider .slick-next {
    width:auto;
    height:auto;
}

.topslider .slick-next {
    right:50px;
    z-index:100;
}

.topslider .slick-prev {
    left:50px;
    z-index:100;
}

.topslider .slick-next, .topslider .slick-prev {
    opacity: 1;
}

.topslider .slick-next:hover, .topslider .slick-prev:hover {
    border:none;
}

.topslider .slick-next:before, .topslider .slick-prev:before {
    transition: 0.8s ease;
    background:rgba(255,255,255,0.7);
    padding: 1.5em;
    font-family: Roboto;
    line-height: normal;
    color: #222;
}

.topslider .slick-next:hover:before, .topslider .slick-prev:hover:before {
    background: white;
    color: black;
}

.topslider .slick-next:before {
    content:"»";
}

.topslider .slick-prev:before {
    content:"«";
}

/* Content-startpage | Blocks
---------------------------------------------------------*/

.inner-content {
    font-size: 1.2rem;
}

.inner-content svg {
    fill: #fff;
    margin-right: 10px;
}

.inner-content a {
    color: #fff;
    text-decoration: none;
}

.block-2  {
    position:relative;
    z-index:10;
    overflow:visible;
    margin-top:-130px;
    padding:1.3em 0;
    color: #222;

}

.block-2 .container .inner-content {
    max-width: 900px;
    margin:0 auto;
    padding:2em;
    position:relative;
    text-align:center;
    background: var(--accent-color);
}

.block-2 .container .inner-content:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.7);
    box-shadow: 0 0 40px rgba(0,0,0,0.7);
    bottom: 0px;
    width: 80%;
    height: 50%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    left: 10%;
    right: 10%;
}

.profiles {

}

.profiles-title {
    background: var(--accent-color);
}

.profiles-title h2 {
    color: #fff;
    text-align: center;
    padding: 2rem;
}

.block-3 {
    background: rgba(29,105,181,.8);
    color: #fff;
    padding: 2rem;
}

.block-3 ul li {
    list-style-type: none;
}

.block-3 ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: #fff;
}


.block-3 .logo img {
    max-width: 300px;
    height: auto;
    padding-top: 100px;
}

.block-3 .logo {
        text-align: center;
}

.kontaktbanner {
    background: linear-gradient(0deg,rgba(45,104,150,.8),rgba(45,104,150,.8)),url('/theme/intendit/graphics/bg1.jpg') fixed center left;
    color: #fff;
}

.kontaktperson-pic {
    min-height: 170px;
}


ul.medlemsexklusivt li {
    list-style-type: none;
}
ul.medlemsexklusivt li a {
    text-decoration: none;
    
}


/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes-outer {
    background: #F7F8F8;
    

}

.box {
    /*background: #eee;*/
}

.box-inner {
    padding: 1rem;
}
.box h3 {
     text-align: center;
}

.boxes img {
    max-width: 150px;
    height: auto;
}

.boximg {
    text-align: center;
    margin: 0 auto;
}

.boxbutton a {
    text-align: center;
    margin: 0 auto;  
}

.boxes {
    width: 100%;

}

.box {
    margin: 0 auto;
}

.boxes a {
    text-decoration: none;
    color: #ccc;
    text-align: center;
}

.boxes a:hover {
    color: #fff;
}

.boxbutton {
    background: var(--effect-color);
    padding: 0.5rem;
}

.searchbutton button {
    border: none;
    background: var(--effect-color);
    color: #fff;
    padding: .8rem;
    transition: .5s ease;
}

.searchbutton button:hover {
    background: var(--accent-color);
}

button, input[type=reset], input[type=submit] {
    border-radius: 0px;
    padding: 0.5rem;
}

.box .button, .box button, .box input[type=reset], .box input[type=submit] {
    margin: 0 0 0 3rem;
}


.box input[type=email], .box input[type=number], .box input[type=search], .box input[type=text], .box input[type=tel], .box input[type=url], .box input[type=password], .box textarea {
    /*max-width: 90%;
    margin: 0 auto;*/
}

/* Sökfunktion Skeppsmäklare
----------------------------------------------------------*/

.col-title {
    font-weight: 600;
}

.filter-result {
    text-align: center;
}

.skeppsmaklare-item {
    background: var(--main-color);
    border-radius: 5px;
}

.skeppsmaklare h4 {
    text-transform: none;
    color: var(--accent-color);
    margin: 1rem 0;
    font-weight: 600;
}

.skeppsmaklare input[type=email], .skeppsmaklare input[type=number], .skeppsmaklare input[type=search], .skeppsmaklare input[type=text], .skeppsmaklare input[type=tel], .skeppsmaklare input[type=url], .skeppsmaklare input[type=password],.skeppsmaklare textarea {
    max-width: 95%;
}

.skeppsmaklare label {
    display: inline;
    margin: 1.5rem;
}

.skeppsmaklare-list {
    margin-top: 2rem;
}


table td, tr {
    border: none;
    padding: .5rem;
}

/* listing items
---------------------------------------------------------*/

.nyheter h1 {
    padding: 1rem;
}
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.maincontent {
    margin-top: 3rem;
}


.Kvalitetssäkring-FQS img {
    max-width: 150px;
    height: auto;
}

/* Kontaktpersoner 
--------------------------------------------------------*/

.kontaktperson img {
    max-width: 110px;
    height: auto;
}

.kontaktperson svg {
    margin-right: .5rem;
}

/* Contact
---------------------------------------------------------*/

.contact-block-l, .contact-block-2 {
}

.kontakt svg {
    margin-right: 0.5rem;
}



/* Footer
---------------------------------------------------------*/

footer {
    background: #222;
    color: #eee;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill: var(--main-color);
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

footer a, footer a:hover {
    color: #fff !important;
}

.footertext {
    font-style: italic; 
    font-family: var(--alternative-font-family);
    color: #ccc;
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: center;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color: #fff;
}

.created-by a {
    color: #fff;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
    margin: .2rem;
}


.boltform textarea {
    min-height:120px;
}

.block-3 .boltforms-row {
    float: left;
    width: 33.3%;
}

.block-3 .boltforms-row:nth-of-type(4) {
    width: 100%;
}

.block-3 .boltforms-row:nth-of-type(5) {
    float: right;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #222;
    padding: 1rem;
}

button[type=submit], input[type=submit] {
    margin:0;
    background: var(--effect-color);
    border: none;
    transition: ease-in 0.5s;
    width: 30%;
    float: right;

}

button[type=submit]:hover, input[type=submit]:hover {
    background: #fff;
    color: #222;
    border: none;
    float: right;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/* Media Queries
---------------------------------------------------------*/

@media (max-width: 1280px) { 
    .menu li a {
        font-size: .9rem;
        padding: .8rem;
        }
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
    .menu.show {
        background: var(--main-color);
        margin-top: 0;
    }
    
    .logo img {
    max-width: 110px;
        
    }
    
    .logo {
    text-align: center;
        margin-top: 10px;
     }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid #000;
    }
    
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .contact-block .boltforms-row {
    float: left;
    width: 100%;
    padding-right: 10px;
}
    .block-3 {
        padding: 0;
        margin: 0;
    }
}

@media print { 
     footer, header, nav, h1, h3, .skeppsmaklare label, input, input[type=checkbox], select{ 
         display: none; 
    }
    .intro-content {
        position: inherit;
    }
    .block-2 .container .inner-content:after {
        position: inherit;
    }
          body, html, .skeppsmaklare-list {
          width: 100%;
          height: 100%;
      }
    .skeppsmaklare-item {
        height: 100%;
        page-break-inside: avoid;
    }
    .site, .flex {
        display: block;
    }
    div[data-search] {
        display: none;
    }
}

/********** FB *************/
@media (min-width: var(--breakpoint-s)) {
    .news-block {
        top: 20em;
        right:12em;
        bottom: 5em;
        width: 50%;
        padding: 1em 2em;
        width: 30%;
        overflow: hidden;
    }
    
    mark {
    background: none;
        color: #fff}

    .news-block-inner {
        overflow-y:scroll;
        height:300px;
    }
}

.news-post p  {
    margin-top:0;
}

.button {
    margin: 0;
    background: var(--effect-color);
    color: white;
    border-radius: 0;
    border: none;
}

.button:hover {
    background: #333;
}

.contact-map iframe {
    border: none !important;
    width:100%;
}

.Valnämnd-revisorer img {
    width:125px;
    height: auto;
}
/********** FB *************/
@media (min-width: var(--breakpoint-s)) {
    .news-block {
        width: 30%;
        overflow: hidden;
    }
    
    mark {
    background: var(--effect-color) !important;
        color: #fff
    }

    .news-block-inner {
        overflow-y:scroll;
        height:500px;
        overflow-x: hidden;
    }
}

.news-post p  {
    margin-top:0;
}

.button {
    margin: 0;
    background: var(--effect-color);
    color: white;
    border-radius: 0;
}


[data-showhide] {display:none; }

.nyheter {

}

.news-item {
    background: #fff;
    border-bottom: 1px solid #eee;
}

.publishdate {
    font-size: .8rem;
    color: #777;
    letter-spacing: 0.10rem;
}

.message-correct {
    display:none;
}

.boxitem {
    box-shadow: 0 0 0 1px #AEAEAE;
    text-decoration:none;
    display:block;
    text-align:center;
    
}

.boxitem:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,.2)
}
